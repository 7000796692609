
import { defineComponent, ref,computed } from "vue";

import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import { useStore } from "vuex";

import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import { Integration } from "@/dtos/Integration";
interface CardData {
  nameOnCard: string;
  cardNumber: string;
  expirationMonth: string;
  expirationYear: string;
  cvv: string;
  saveCard: boolean;
}
export default defineComponent({
  name: "upgrade-plan-modal",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  props : {
    integrations: Array
  },
  setup(props) {
    const store = useStore();
    const current = ref<"month" | "annual">("month");

    const selected = ref("Starter");
    const selectedPrice = ref(0);
    const secondStep = ref(false);

    const plans = [


      {
        title: "Starter",
        subTitle: "Tüm entegrasyonları tek bir panelden yönetin.",
        description: "Günlük ürün üreten veya 100 üzeri ürünü olan işletmeler için ideal",
        priceMonth: 199,
        priceAnnual: 1990,
        default: true,
        custom: false,
        features: [
          {
            title: "YemekSepeti, Getir Yemek, Trenyol Yemek, Migros Yemek ve PaketServisi Entegrasyonları",
            supported: true,
          },
          {
            title: "Ürün kapatıp açma",
            supported: true,
          },
          {
            title: "Opsiyon kapatıp açma",
            supported: true,
          },
          {
            title: "Yorum cevaplama",
            supported: true,
          },
          {
            title: "Otomatik ürün kapatma/açma",
            supported: false,
          },
          {
            title: "Yapay zeka destekli otomatik yorum cevaplama",
            supported: false,
          },
          {
            title: "Tüm platformlarda ürün ve opsiyonlarda günlük kapat seçeneği",
            supported: false,
          },
          {
            title: "Tek seferde tüm entegrasyonlarda fiyat güncelleme",
            supported: false,
          },
          {
            title: "İleri tarihli fiyat güncelleme",
            supported: false,
          },
        ],
      },

      {
        title: "Automated",
        subTitle: "Satışlarını riske atmak istemeyen işletmeciler için ideal",
        description: "%100 Müşteri memnuniyeti için her bir yorumu otomatik olarak zamanında yanıtlayın, fiyat güncellemeleri için bilgisayar başında beklemeyin.",
        priceMonth: 299,
        priceAnnual: 2990,
        label: "En Popüler",
        default: false,
        custom: false,
        features: [
          {
            title: "YemekSepeti, Getir Yemek, Trenyol Yemek, Migros Yemek ve PaketServisi Entegrasyonları",
            supported: true,
          },
          {
            title: "Ürün kapatıp açma",
            supported: true,
          },
          {
            title: "Opsiyon kapatıp açma",
            supported: true,
          },
          {
            title: "Yorum cevaplama",
            supported: true,
          },
          {
            title: "Otomatik ürün kapatma/açma",
            supported: true,
          },
          {
            title: "Yapay zeka destekli otomatik yorum cevaplama",
            supported: true,
          },
          {
            title: "Tüm platformlarda ürün ve opsiyonlarda günlük kapat seçeneği",
            supported: true,
          },
          {
            title: "Tek seferde tüm entegrasyonlarda fiyat güncelleme",
            supported: true,
          },
          {
            title: "İleri tarihli fiyat güncelleme",
            supported: true,
          },
        ],
      },

    ];
    selectedPrice.value = plans[0].priceMonth;
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newCardModalRef = ref<null | HTMLElement>(null);

    const cardData = ref<CardData>({
      nameOnCard: "",
      cardNumber: "",
      expirationMonth: "",
      expirationYear: "",
      cvv: "",
      saveCard: true
    });

    const validationSchema = Yup.object().shape({
      nameOnCard: Yup.string().required().label("İsim"),
      cardNumber: Yup.string().required().label("Kart Numarası"),
      expirationMonth: Yup.string().required().label("Ay"),
      expirationYear: Yup.string().required().label("Yıl"),
      cvv: Yup.string().required().label("CVV"),
    });
    const totalPrice = computed(() => {
      const selectedPlan = plans.find(p=>p.title == selected.value);
      if (selectedPlan)
        return ((props.integrations?.length ?? 0) -1 ) * (current.value !="month" ? selectedPlan?.priceAnnual:selectedPlan?.priceMonth);
      return 0;
    });
    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      store
        .dispatch(Actions.MAKEPAYMENT, {
          cardHolderName: cardData.value.nameOnCard,
          cardNumber: cardData.value.cardNumber,
          expireMonth: cardData.value.expirationMonth,
          expireYear4Digit: cardData.value.expirationYear,
          cvc: cardData.value.cvv,
          saveCard: cardData.value.saveCard,
          planId: selected.value,
          annual: current.value != "month"
        })
        .then((response) => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          debugger;
          //Check if payment completed in
          if (response.status == "success") {
            Swal.fire({
              text: "Ödeme Başarılı bir şekilde alındı ve üyelik süreniz uzatıldı.",
              title: "Ödeme",
              icon: "success",
              confirmButtonText: "Tamam",
            }).then(() => {
              hideModal(newCardModalRef.value);
            });
          } else {
            //Show error message
            Swal.fire({
              title: "Hata!",
              text: "Ödemeniz alınamadı lütfen kart bilgilerini kontrol ederek tekrar deneyiniz. Hata mesajı : " + response.errorMessage,
              icon: "error",
              confirmButtonText: "Tamam",
            });
          }
        })
        .catch((error) => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          Swal.fire({
            title: "Hata!",
            text: "Ödemeniz alınamadı lütfen kart bilgilerini kontrol ederek tekrar deneyiniz...",
            icon: "error",
            confirmButtonText: "Tamam",
          });
        });
    };
    return {
      plans,
      current,
      selected,
      totalPrice,
      selectedPrice,
      secondStep,
      cardData,
      validationSchema,
      submit,
      submitButtonRef,
      newCardModalRef,
    };
  },
});
